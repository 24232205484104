<template>
  <q-card
    class="login"
    flat
    :class="$q.platform.is.mobile ? 'q-pa-sm  transparent  ' : 'q-pa-md'"
    :style="$q.platform.is.mobile ? `width: ${$q.screen.width - 10}px` : `width: 448px`"
  >
    <q-form @submit="login(form)">
      <q-card-section class="text-center q-pt-md q-pb-none">
        <q-img
          :src="require('@/assets/images/logo_color.svg')"
          spinner-color="white"
          width="168px"
          spinner-size="82px"
        />
      </q-card-section>
      <q-card-section class="q-gutter-sm q-pb-none">
        <q-input
          outlined
          bg-color="white"
          type="email"
          v-model="form.username"
          :placeholder="$t('enter_the_email')"
          clearable
          class="rounded"
          clear-icon="close"
          label="Email"
          @keyup.enter="submit"
          lazy-rules
          :rules="[isRequired, isEmail]"
        >
          <template v-slot:prepend>
            <q-icon name="mdi-email-outline" color="primary" />
          </template>
        </q-input>
        <q-input
          clearable
          bg-color="white"
          clear-icon="close"
          outlined
          class="rounded"
          :placeholder="$t('enter_the_pass')"
          v-model="form.password"
          ref="password"
          :label="$t('password')"
          :type="showPassword ? 'text' : 'password'"
          @keyup.enter="submit"
          lazy-rules
          :rules="[isRequired]"
        >
          <template v-slot:prepend>
            <q-icon name="mdi-lock-open-outline" color="primary" />
          </template>
          <template v-slot:append>
            <q-btn
              flat
              round
              color="primary"
              no-caps
              :icon="showPassword ? 'mdi-eye-off-outline' : 'mdi-eye-outline'"
              @click="showPassword = !showPassword"
            />
          </template>
        </q-input>
      </q-card-section>
      <q-card-section>
        <vue-recaptcha
          sitekey="6LfY4HwjAAAAAGeM8BCS3CDIxW71QPsJusc82dvJ"
          ref="recaptcha"
          @verify="
            () => {
              checkCaptcha = true;
            }
          "
          @expired="
            () => {
              checkCaptcha = false;
            }
          "
          @error="
            () => {
              checkCaptcha = true;
            }"
        />
        <text-subtitle2 v-if="!checkCaptcha && erroLogin">Por favor selecione a caixa acima.</text-subtitle2>
      </q-card-section>
      <q-card-section>
        <q-btn
          unelevated
          color="primary"
          no-caps
          class="full-width q-mb-md q-py-sm text-body1"
          :label="$t('login')"
          type="submit"
        />
        <div class="row justify-between">
          <q-btn no-caps unelevated flat :label="$t('recover_pass')" to="/auth/forgot_password" />
          <q-btn unelevated flat no-caps color="primary" :label="$t('register')" to="/auth/register" />
        </div>
      </q-card-section>
    </q-form>
  </q-card>
</template>

<script>
import AccountMixin from '@/mixins/AccountMixin';
import AuthService from '../../services/AuthService';
import { VueRecaptcha } from 'vue-recaptcha';

export default {
  name: 'Login',
  mixins: [AccountMixin, AuthService],
  components: {
    VueRecaptcha
  },
  data() {
    return {
      form: {
        username: '',
        password: ''
      },
      showPassword: false,
      checkCaptcha: false,
      erroLogin: false
    };
  },
  methods: {
    /**
     * login
     * @param {*} form
     */

    /*  showDialog () {
            this.$q.dialog({
            title: 'Atenção<em>!</em>',
            message: `<span>No momento não é possível realizar essa ação, por favor entre em contato com o nosso SAC.</span>
             <br><br> <span>Email: </span> <strong>suporte@afil.io</strong><br> <span>Telefone: </span> 
             <strong>(+55) 21  2244-8862</strong> <br> 
             <span>Whatsapp: </span> <strong><a class="text-primary" href="https://api.whatsapp.com/send?phone=5511956389610" target="_blank">(+55) 11 95638-9610</a></strong>`,
            html: true
            })
        }, */

    async login(form) {
      if (this.checkCaptcha) {
        this.onLoading(true);
        try {
          if (!form.username) throw this.$t('validator.required.email');
          if (!form.password) throw this.$t('validator.required.password');
          const { data, status } = await this.authLogin(this.form);
          if (status === 200) {
            if (data?.login?.user?.profile === 'anunciante')
              window.location = process.env.VUE_APP_ADVERTISER + `/auth/${data.login.auth.access_token}`;
            else {
              this.ActionSetToken(data.login.auth.access_token);
              this.ActionSetUser(data.login.auth.user);
              localStorage.setItem('afilio_store', data.login.auth.access_token);
              localStorage.setItem('afilio_language', 'pt-br');
              this.$router.push({ name: 'Dashboard' });
            }
          }
        } catch (error) {
          if (error.response && error.response.status === 500)
            this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
          else this.errorNotify(error);
          this.$refs.recaptcha.reset();
          this.checkCaptcha = false;
        } finally {
          this.onLoading(false);
        }
      } else {
        this.erroLogin = true;
      }
    }
  }
};
</script>

<style scoped>
text-subtitle2 {
  color: red;
}
</style>
